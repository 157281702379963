import { VARIABLES } from "../../strings/variables.js";
import { reportError } from "../../logging/logger.js";

import axios from "axios";

const axiosConfig = {
  withCredentials: true,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

const loginUserAction = (userObj, isUnauth) => ({
  type: "LOGIN_USER",
  userObj,
  isUnauth,
});

const loginUserActionIntermediate = (...args) => {
  return loginUserAction(...args);
};

const logoutUserAction = (authFailed) => ({
  type: "LOGOUT_USER",
  authFailed,
});

const logoutUserActionIntermediate = (...args) => {
  return logoutUserAction(...args);
};

const updateUserAction = (userObj) => ({
  type: "UPDATE_USER",
  userObj,
});

const resetAuthAction = () => ({
  type: "RESET_AUTH",
});

export function loginUser(user) {
  return (dispatch) => {
    dispatch(loginUserActionIntermediate(user, false));
  };
}

export function getUserFetch() {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        VARIABLES.apiUrl + "auth/general/getuser",
        axiosConfig,
      );

      if (response.data.success) {
        const user = response.data.user;

        if (response.data.userType === "unauth") {
          dispatch(loginUserActionIntermediate(user, true));
        } else if (response.data.userType === "regular") {
          dispatch(loginUserActionIntermediate(user));
        } else {
          dispatch(logoutUserActionIntermediate(true));
        }
      } else {
        dispatch(logoutUserActionIntermediate(true));
      }
    } catch (error) {
      reportError(error);
      dispatch(logoutUserActionIntermediate(true));
    }
  };
}

export function userUpdate(user) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        VARIABLES.apiUrl + "updateuser",
        user,
        axiosConfig,
      );

      const updatedUser = response.data.user;

      if (updatedUser == null) {
        return;
      }

      dispatch(updateUserAction(updatedUser));
    } catch (error) {
      reportError(error);
      console.log("Could not update the user!");
    }
  };
}

export function userUnauthUpdate(user) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        VARIABLES.apiUrl + "auth/unauth/updateuserunauth",
        user,
        axiosConfig,
      );

      const updatedUser = response.data.user;

      if (updatedUser == null) {
        return;
      }

      dispatch(updateUserAction(updatedUser));
    } catch (error) {
      reportError(error);
      console.log("Could not update the unauth user!");
    }
  };
}

export function tokenFacebookFetch(params) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        VARIABLES.apiUrl + "login-or-signup-facebook",
        {
          access_token: params.uid,
          roomsToAdd: params.roomsToAdd,
          roomSpacesToAdd: params.roomSpacesToAdd,
          roomToRegister: params.roomToRegister,
        },
        axiosConfig,
      );

      const user = response.data.user;

      if (user != null) {
        dispatch(loginUserActionIntermediate(user));
      } else {
        dispatch(logoutUserActionIntermediate(true));
      }
    } catch (error) {
      reportError(error);
      console.log("Not able to login", error);
      dispatch(logoutUserActionIntermediate(true));
    }
  };
}

export function userLoginFetch(user) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        VARIABLES.apiUrl + "login",
        user,
        axiosConfig,
      );

      const userUpdated = response.data.user;

      if (userUpdated != null) {
        dispatch(loginUserActionIntermediate(userUpdated));
      } else {
        dispatch(logoutUserActionIntermediate(true));
      }
    } catch (error) {
      reportError(error);
      console.log("Not able to login", error);
      dispatch(logoutUserActionIntermediate(true));
    }
  };
}

export function userUnauthLoginFetch(user) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        VARIABLES.apiUrl + "auth/unauth/registerorlogin",
        user,
        axiosConfig,
      );

      const userUpdated = response.data.user;

      if (userUpdated != null) {
        dispatch(loginUserActionIntermediate(userUpdated, true));
      } else {
        dispatch(logoutUserActionIntermediate(true));
      }
    } catch (error) {
      reportError(error);
      console.log("Not able to login", error);
      dispatch(logoutUserActionIntermediate(true));
    }
  };
}
export function userLogout() {
  return async (dispatch) => {
    try {
      await axios.get(VARIABLES.apiUrl + "auth/general/logout", axiosConfig);

      dispatch(logoutUserActionIntermediate(false));
    } catch (error) {
      reportError(error);
      console.log("Could not log out", error);
    }
  };
}

export function userUnauthLogout() {
  return async (dispatch) => {
    try {
      await axios.get(VARIABLES.apiUrl + "auth/general/logout", axiosConfig);

      dispatch(logoutUserActionIntermediate(false));
    } catch (error) {
      reportError(error);
      console.log("Could not log out", error);
    }
  };
}

export function resetAuth() {
  return (dispatch) => {
    dispatch(resetAuthAction());
  };
}

export function tryUnauthLoginWithToken(authToken) {
  return async (dispatch) => {
    try {
      if (typeof authToken !== "string" || authToken.length < 1) {
        console.log("Invalid token");
        return;
      }

      const response = await axios.post(
        VARIABLES.apiUrl + "auth/unauth/authenticateWithToken",
        { authToken },
        axiosConfig,
      );

      if (response.data.success) {
        const user = response.data.user;

        dispatch(loginUserActionIntermediate(user, true));
      }
    } catch (error) {
      reportError(error);
    }
  };
}

export function tryQbrickLoginWithToken(qbrickToken) {
  return async (dispatch) => {
    try {
      if (typeof qbrickToken !== "string" || qbrickToken.length < 1) {
        console.log("Invalid token");
        return;
      }

      const response = await axios.post(
        VARIABLES.apiUrl + "qbrick-login",
        { qbrickToken },
        axiosConfig,
      );

      const userUpdated = response.data.user;

      if (userUpdated != null) {
        dispatch(loginUserActionIntermediate(userUpdated));
      } else {
        dispatch(logoutUserActionIntermediate(true));
      }
    } catch (error) {
      reportError(error);
      console.log("Not able to login (w. Qbrick)", error);
      dispatch(logoutUserActionIntermediate(true));
    }
  };
}
